<template>
  <fw-panel :title="$t('edit_document')">
    <div class="flex flex-col">
      <div>
        <fw-label>{{ $t('title') }}</fw-label>
        <TextInput v-model="title" :minlength="2" :maxlength="250" autofocus> </TextInput>

        <fw-tip v-if="$v.title.$error" error>
          <span v-if="!$v.title.min">Insira no mínimo 2 caracteres</span>
          <span v-if="!$v.title.max">Insira no máximo 250 caracteres</span>
          <span v-else>Insira o título do documento</span>
        </fw-tip>
      </div>

      <b-field v-if="!showSearch && showLanguages" :label="$t('select_an_option')" class="mt-1">
        <div class="flex gap-3 items-center">
          <b-radio v-model="language" name="language" native-value="pt">Português</b-radio>
          <b-radio v-model="language" name="language" native-value="en-GB"> Inglês (Reino Unido) </b-radio>
          <fw-button v-if="!showSearch" type="xlight" class="" @click.native="showLanguageSearch()">
            {{ $t('see_all_languages') }}
          </fw-button>
        </div>
      </b-field>

      <b-field v-if="showSearch && showLanguages" :label="$t('select_an_option')">
        <b-autocomplete
          ref="languageSearch"
          v-model="languageSearch"
          :placeholder="$t('search_language_example')"
          :data="filteredLanguage"
          :clearable="true"
          field="name"
          :append-to-body="true"
          :open-on-focus="true"
          @select="option => (language = option.id)"
        >
        </b-autocomplete>
      </b-field>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button :type="loading ? 'disabled' : 'primary'" :disable="loading" class="w-32" @click.native="save()">
        {{ $t('save') }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import ServicePages from '../../services/ServicePages'
import locales from '@/fw-modules/fw-core-vue/utilities/locales.json'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ModalEditPage',
  components: {
    TextInput
  },
  props: {
    injectPayload: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      required: true
    },
    showLanguages: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: '',
      language: 'pt',
      loading: false,
      showSearch: false,
      availableLanguages: locales,
      languageSearch: '',
      isOther: false
    }
  },

  validations: {
    title: { required, min: minLength(2), max: maxLength(250) }
  },

  computed: {
    allowCreate() {
      return this.title.length > 0 && this.language != null
    },
    filteredLanguage() {
      return this.availableLanguages.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.languageSearch.toLowerCase()) >= 0
        )
      })
    }
  },
  watch: {
    isOther() {
      if (this.isOther) {
        this.language = null
      } else {
        this.language = 'pt'
      }
    },
    language() {
      if (this.language != 'pt' && this.language != 'en-GB') {
        this.showSearch = true
      } else {
        this.showSearch = false
      }
    }
  },

  beforeMount() {
    this.title = this.data.title
    this.language = this.data.language
  },

  methods: {
    showLanguageSearch() {
      this.showSearch = true
      this.language = null
      //focus
      this.$nextTick(() => {
        this.$refs.languageSearch.focus()
      })
    },
    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        let result = await ServicePages.updatePage(this.data.key, {
          language: this.language,
          title: this.title,
          ...this.injectPayload
        })
        this.$emit('updated', result)
      })

      this.loading = false
    },
    cancel() {
      if (!this.loading) {
        this.$emit('close')
      }
    }
  }
}
</script>

<i18n lang="json">
{
  "en": {
    "edit_document": "Edit document",
    "see_all_languages": "See all languages",
    "title": "Title",
    "new_document_translation": "New document translation",
    "select_an_option": "Select a language",
    "search_language_example": "e.g. English - American",
    "cancel": "Cancel",
    "create": "Create"
  },
  "pt": {
    "edit_document": "Editar documento",
    "title": "Título",
    "see_all_languages": "Ver todos os idiomas",
    "new_document_translation": "Nova tradução de documento",
    "select_an_option": "Selecione um idioma",
    "search_language_example": "exemplo, English - American",
    "cancel": "Cancelar",
    "create": "Criar"
  }
}
</i18n>
